import Service from "../Service";

const resource = "dollProccess/";

export default {
    palletGoing(filter, requestID) {
        return Service.post(resource + "palletGoing", filter, {
            params: { requestID: requestID },
        });
    },
    list(filter, requestID) {
        return Service.post(resource + "list", filter, {
            params: { requestID: requestID },
        });
    },
    save(doll, requestID) {
        return Service.post(resource + "save", doll, {
            params: { requestID: requestID },
        });
    },
    listStore(requestID) {
        return Service.post(resource + "listStorage", {}, {
            params: { requestID: requestID },
        });
    },
    listDollFilter(obj, requestID) {
        return Service.post(resource + "listDollFilter", obj, {
            params: { requestID: requestID },
        });
    },
    savePosition(doll, requestID) {
        return Service.post(resource + "saveposition", doll, {
            params: { requestID: requestID },
        });
    },
}