<template>
	<div>
		<v-stepper v-model="stageTransfer">
			<v-stepper-header>
				<v-stepper-step
					:complete="stageTransfer > 1"
					step="1"
				>
					{{ "Información - " + CttName }}
				</v-stepper-step>
				<v-divider></v-divider>
				<v-stepper-step
					:complete="stageTransfer > 2"
					step="2"
				>
					Mesas
				</v-stepper-step>
			</v-stepper-header>

			<v-stepper-items>
				<v-stepper-content step="1">
					<v-card style="margin-top: -5px;">
						<v-card-subtitle>
							<v-row>
								<v-col>
									{{ "Muñecos en proceso" }}
								</v-col>
								<v-spacer></v-spacer>
								<v-btn
									fab
									x-small
									color="primary"
									@click="stageTransfer = 2"
									v-if="dollTransfer > 0"
								>
									<v-icon>mdi-arrow-right-bold-box-outline</v-icon>
								</v-btn>
							</v-row>
						</v-card-subtitle>
						<v-card-text>
							<v-row align="center">
								<v-col
									v-for="jaba in dolls"
									:key="jaba.DlpID"
									align="center"
								>
									<v-card width="160" height="270">
										<v-card-title>
											<v-row>
												<v-col>
													{{ jaba.DlpID }}
												</v-col>
												<v-spacer></v-spacer>
												<v-radio-group
													v-model="dollTransfer"
													style="margin-top: 5px;
													margin-right: -9px;"
													hide-details
												>
													<v-radio
														:value="jaba.DlpID"
													></v-radio>
												</v-radio-group>
											</v-row>
											
											
										</v-card-title>
										<v-card-subtitle>
											{{  "N° Jabas : "+ jaba.DlpQuantity+ " - " +"N° Pallet" + jaba.NPallet + " - " +  jaba.DlpJuliano + " - " + jaba.TypeSelectionFrozenName + " - " + jaba.TypeFrozenCategoryName}}
										</v-card-subtitle>
										<v-card-text>
											<v-img
												src="./../../../../public/static/jabamuneco.jpg"
											></v-img>
										</v-card-text>
										<v-chip
											class="ma-2"
											color="red"
											text-color="white"
										>
											{{ jaba.DlpCodBase}}
										</v-chip>
									</v-card>
									
								</v-col>
							</v-row>
						</v-card-text>
						<v-card-actions>
							<v-btn
								color="success"
								text
								@click=" ShowViewDetails()"
							>
								Ver más
								<v-icon>{{ showMore ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
							</v-btn>
							<v-spacer></v-spacer>
							<v-btn
								color="orange"
								text
								@click="CloseDialogInfoTable()"
							>
								Cancelar
							</v-btn>
						</v-card-actions>
						<v-expand-transition>
							<div v-show="showMore">
								<v-divider></v-divider>
								<v-card-subtitle>
									{{ "Ultimos 10 Muñecos procesados" }}
								</v-card-subtitle>
								<v-card-text>
									<v-data-table
										dense
										:headers="headerDoll"
										:items="detailsDoll"
										hide-default-footer
									></v-data-table>
								</v-card-text>
							</div>
						</v-expand-transition>
					</v-card>
				</v-stepper-content>

				<v-stepper-content step="2">
					<v-card style="margin-top: -10px;">
						<v-card-text>
							<v-row>
								<v-col>
									<s-text
										label="Cantidad jabas"
										number
										v-model="QuantityJabasTransfer"
										:min="1"
										:max="4"
									></s-text>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<s-text
										label="Numero de Base"
										number
										v-model="itemHead.DlpCodBase"
										ref="txtDlpCodBase"
										:min="1"
										v-if="mDlpCodBase"	
									></s-text>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<v-tabs v-model="ItemSelect">
										<v-tab href="#Lines">Lineas</v-tab>
										<v-tab href="#Store">Almacen</v-tab>
									</v-tabs>
									<v-tabs-items v-model="ItemSelect">
										<v-tab-item :value="'Lines'">
											<tables-line
												:filter="filter"
												style="margin-top: 15px;"
												@emitTable="emitTable($event)"
											></tables-line>
										</v-tab-item>
										<v-tab-item  :value="'Store'">
											<send-doll
												@emitLocation="emitLocation($event)"></send-doll>
										</v-tab-item>
									</v-tabs-items>
									
								</v-col>
							</v-row>
						</v-card-text>
						<v-card-actions>
							<v-btn
								color="success"
								text
								@click="DollTransferAction()"
							>
								Guardar
							</v-btn>
							<v-spacer></v-spacer>
							<v-btn
								color="orange"
								text
								@click="stageTransfer = 1"
							>
								Cancelar
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-stepper-content>
			</v-stepper-items>
		</v-stepper>
		<!-- <v-card dense outlined>
			<v-card-title>
				<h4>{{ "Información - " + table.CttName }}</h4>
			</v-card-title>
			<v-card-subtitle>
				<v-row>
					<v-col>
						{{ "Muñecos en proceso" }}
					</v-col>
					<v-spacer></v-spacer>
					<v-btn
						fab
						x-small
						color="primary"
						@click="DollTransferAction()"
						v-if="dollTransfer > 0"
					>
						<v-icon>mdi-arrow-right-bold-box-outline</v-icon>
					</v-btn>
				</v-row>
			</v-card-subtitle>
			<v-card-text>
				<v-row align="center">
					<v-col
						v-for="jaba in dolls"
						:key="jaba.DlpID"
						align="center"
					>
						<v-card width="120">
							<v-card-title>
								<v-row>
									<v-col>
										{{ jaba.DlpID }}
									</v-col>
									<v-spacer></v-spacer>
									<v-radio-group
										v-model="dollTransfer"
										style="margin-top: 5px;
										margin-right: -9px;"
										hide-details
									>
										<v-radio
											:value="jaba.DlpID"
										></v-radio>
									</v-radio-group>
								</v-row>
								
								
							</v-card-title>
							<v-card-text>
								<v-img
									src="./../../../../public/static/jabamuneco.jpg"
								></v-img>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions>
				<v-btn
					color="orange"
					text
					@click="CloseDialogInfoTable()"
				>
					Cancelar
				</v-btn>
			</v-card-actions>
		</v-card>
		<v-card dense outlined>
			<v-card-subtitle>
				{{ "Ultimos 10 Muñecos procesados" }}
			</v-card-subtitle>
			<v-card-text>
				<v-data-table
					dense
					:headers="headerDoll"
					hide-default-footer
				></v-data-table>
			</v-card-text>
		</v-card> -->
	</div>
</template>

<script>
	import _dollTableService from "@/services/FrozenProduction/FrzDollTableService.js"
 	import SendDoll from '../ArmedDoll/SendDollNew.vue'
	import TablesLine from '@/components/FrozenProduction/Aconditionado/TablesLine.vue';
	import _armedDoolService from '@/services/FrozenProduction/ArmedDollService'
	
	export default {
		// props: {
		// 	table: {
		// 		type: Object,
		// 		default: null
		// 	},
		// },
		props: {
			dolls: {
				type: Array,
				default: null
			},
			CttName: {
				type: String,
				default: ""
			},
			IsTransfer: {
				type: Number,
				default: 1
			},
		    filter: {},
			CttID: 0,
			mDlpCodBase:{ default :false },
			
		},
		components: {
			TablesLine, SendDoll
		},

		data() {
			return {
				stageTransfer: 1,
				showMore: false,
				// dolls: [],
				headerDoll : [
					{text: "ID", value: "DlpID"},
					{text: "N° Pallet", value:"NPallet"},
					{text: "Juliano", value: "DtbJuliano"},
					{text: "Jabas", value: "DlpQuantity"},
					{text: "Categoria", value: "TypeFrozenCategoryName"},
					{text: "Seleccion", value: "TypeSelectionFrozenName"},
				],
				dollTransfer: 0,
				dollSelectTransfer: {},
				QuantityJabasTransfer: 0,
				tableSelect: {},
				detailsDoll:[],
				itemHead: {},
				ItemSelect: "tab-Funciones",
				componentKey: 0,
				TypeSend: 0
			}
		},

		watch: {
			stageTransfer() {
				if(this.stageTransfer == 2)
				{
					this.dollSelectTransfer = this.dolls.find(element => element.DlpID == this.dollTransfer);
					if(this.dollSelectTransfer != null)
					{
						this.QuantityJabasTransfer = this.dollSelectTransfer.DlpQuantity;
						this.itemHead.DlpCodBase = this.dollSelectTransfer.DlpCodBase;
					}
				}
			}
		},

		methods: {
			emitTable(table)
			{
				this.tableSelect = table;
				this.TypeSend = 1;//envio lineas
				
			},
			emitLocation(Location){
				this.TypeSend = 2;
				this.tableSelect = Location;
				// this.tableSelect.forEach( r => {
				this.dollSelectTransfer.DlpCol = this.tableSelect.item.DedDescription;
				this.dollSelectTransfer.DlpRow = this.tableSelect.item2.DedDescription;      
				this.dollSelectTransfer.DlpID = this.dolls[0].DlpID;       
				// });
				//envio almacen
				
			},

			CloseDialogInfoTable() {
				this.$emit("CloseDialogInfoTable")
			},

			GetDollByTable()
			{
				

				let filter = {
					CttID : this.CttID,
					ClpID : this.filter.ClpID,
					DtbStatus : 2
            	}

				
				_dollTableService
				.list(filter, this.$fun.getUserID())
					.then(resp => {
						if(resp.status == 200)
						{
							this.detailsDoll = resp.data;
							
						}
					})
			},

			DollTransferAction()
			{
				
				if(this.QuantityJabasTransfer == 0 || this.QuantityJabasTransfer == "")
				{
					this.$fun.alert("El numero de jabas debe ser mayor a 0","warning");
					return;
				}
				this.$fun.alert("¿Seguro de transferir?", "question")
				.then(r =>{
					if(r.value)
					{
						if(this.TypeSend == 1)
						{		
							if(this.itemHead.DlpCodBase == ''){
								this.$fun.alert("Ingrese codigo de base","warning");
								this.$refs.txtDlpCodBase.focus();
								return
							}
							
							if(this.tableSelect.CttID == undefined )
							{
								this.$fun.alert("Error no se permite mover,No se ha selecionado una Estación de Trabajo","warning");
								return;
							}				
							this.stageTransfer = 2;
							Object.assign(this.dollSelectTransfer, this.tableSelect)
							this.dollSelectTransfer.IsTransfer = this.IsTransfer;
							this.dollSelectTransfer.DlpQuantity = this.QuantityJabasTransfer;
							this.dollSelectTransfer.DlpIDTransfer = this.dollSelectTransfer.DlpID;
							this.dollSelectTransfer.CttIDTransfer =  this.CttID //this.dollSelectTransfer.CttID; // this.CttID
							this.dollSelectTransfer.UsrCreateID = this.$fun.getUserID();
							this.dollSelectTransfer.UsrUpdateID = this.$fun.getUserID();
							this.dollSelectTransfer.DtbID = 0;
							this.dollSelectTransfer.ClpIDX = this.tableSelect.ClpID; // this.filter.ClpID; //this.tableSelect.ClpID;
							this.dollSelectTransfer.ClpIDOrigin =this.filter.ClpID;
							this.dollSelectTransfer.DlpCodBase = this.itemHead.DlpCodBase;
							
							
							//  return
							_dollTableService
							.save(this.dollSelectTransfer, this.$fun.getUserID())
							.then(resp => {
								if(resp.status == 200)
								{
									this.$fun.alert("Transferido Correctamente", "success");
									this.ClearFormTransfer();
									this.CloseDialogInfoTable();

									this.RefreshStore();
								}
							})
						}else{
							
							this.dollSelectTransfer.DlpQuantity = this.QuantityJabasTransfer;
							this.dollSelectTransfer.UsrCreateID = this.$fun.getUserID();
							this.dollSelectTransfer.UsrUpdateID = this.$fun.getUserID();
							_armedDoolService.savePosition( this.dollSelectTransfer, this.$fun.getUserID())
							.then((r) => {

								if (r.status == 200) {
									this.$fun.alert("Guardado correctamente", "success");	
									this.CloseDialogInfoTable();
									this.RefreshStore();
								}
							})    
						}
					}
				});
			},

			ClearFormTransfer()
			{
				this.dollSelectTransfer = {};
				this.QuantityJabasTransfer = 0;
				this.dollTransfer = 0;
				this.tableSelect = {};
				this.stageTransfer = 1;
			},

			ShowViewDetails()
			{
				if(this.showMore)
				{
					this.showMore = false;
				}else{
					this.showMore = true;
					this.GetDollByTable();
				}
				
			},
			RefreshStore(){
				this.$emit('RefreshStore');
				
			},


		},

		mounted () {
			// this.GetDollByTable();	
	
			
		},
		created()
		{			
			
			
		},
		
	}
</script>