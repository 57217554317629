import Service from "../Service";

const resource = "FrzCtnLinesProcess/";

export default {

    save(prd, requestID) {
        return Service.post(resource + "save", prd, {
            params: { requestID: requestID },
        });
    },
 
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },
    list(obj,requestID) {
        return Service.post(resource + "list", obj, {
            params: { requestID: requestID },
        });
    },
    listLineas(obj, requestID) {
        return Service.post(resource + "listLineas", obj, {
            params: { requestID: requestID },
        });
    },
    listTablesByLines(obj,requestID) {
        return Service.post(resource + "listTablesByLines", obj, {
            params: { requestID: requestID },
        });
    },
    listGroupPerson(obj,requestID) {
        return Service.post(resource + "listGroupPerson", obj, {
            params: { requestID: requestID },
        });
    },
    listGroupByTables(obj,requestID) {
        return Service.post(resource + "listGroupByTables", obj, {
            params: { requestID: requestID },
        });
    },
    listExitcatByCat(obj,requestID) {
        return Service.post(resource + "listExitcatByCat", obj, {
            params: { requestID: requestID },
        });
    },

    linesactive(obj, requestID) {
        return Service.post(resource + "linesactive", obj, {
            params: { requestID: requestID },
        });
    },
    //
    saveTransfer(items, requestID) {
        return Service.post(resource + "saveTransfer", items, {
            params: { requestID: requestID },
        });
    },
    initial(item, requestID) {
        return Service.post(resource + "initial", item, {
            params: { requestID: requestID },
        });
    },
    saveAssistance(obj, requestID) {
        return Service.post(resource + "saveAssistance", obj, {
            params: { requestID: requestID },
        });
    },
    saveObsGroup(obj, requestID) {
        return Service.post(resource + "saveObsGroup", obj, {
            params: { requestID: requestID },
        });
    },
    listObsGroup(obj, requestID) {
        return Service.post(resource + "listObsGroup", obj, {
            params: { requestID: requestID },
        });
    },
    linesStartProcess(obj, requestID) {
        return Service.post(resource + "linesStartProcess", obj, {
            params: { requestID: requestID },
        });
    },
    linesProcessEnd(obj, requestID) {
        return Service.post(resource + "linesProcessEnd", obj, {
            params: { requestID: requestID },
        });
    },
    linesEnd(obj, requestID) {
        return Service.post(resource + "linesEnd", obj, {
            params: { requestID: requestID },
        });
    },
    linesEndProcess(obj, requestID) {
        return Service.post(resource + "linesEndProcess", obj, {
            params: { requestID: requestID },
        });
    },
    saveResponsablesLine(obj, requestID) {
        return Service.post(resource + "saveResponsablesLine", obj, {
            params: { requestID: requestID },
        });
    },
    listdetail(obj, requestID) {
        return Service.post(resource + "listdetail", obj, {
            params: { requestID: requestID },
        });
    },
    listConfHours(obj, requestID) {
        return Service.post(resource + "listConfHours", obj, {
            params: { requestID: requestID },
        });
    },
    ConfInitial(obj, requestID) {
        return Service.post(resource + "ConfInitial", obj, {
            params: { requestID: requestID },
        });
    },

    listPeriodoActivo(obj, requestID) {
        return Service.post(resource + "listPeriodoActivo", obj, {
            params: { requestID: requestID },
        });
    },

    getLineasCreated(obj, requestID) {
        return Service.post(resource + "linescreated", obj, {
            params: { requestID: requestID },
        });
    },

    getDataLine(obj, requestID) {
        return Service.post(resource + "getdataline", obj, {
            params: { requestID: requestID },
        });
    },
    
}; 