<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-text>
                        <v-row>
                            <s-select-definition
                                :def="1423"
                                @getDataInitial="GetColumns($event)"
                                hidden
                            ></s-select-definition>
                            <s-select-definition
                                :def="1424"
                                @getDataInitial="GetRows($event)"
                                hidden
                            ></s-select-definition>
                        </v-row>
                        <v-row>
                            <v-col cols="auto" v-for="item in columns"
                                :key="item.DedAbbreviation">
                                {{ item.DedDescription }}
                                <v-card theme="dark" hover>
                                    <v-card-text>
                                        <v-row v-for="item2 in rows"
                                            :key="item2.DedAbbreviation">
                                            <v-row class="mt-1" dense>
                                                <v-col cols="auto"  class="py-1 pe-0">
                                                    <v-card v-if="dollStore?.filter(doll => doll.DlpPosition == item.DedDescription + item2.DedDescription).length > 0"
                                                        color="red" disabled>
                                                        <v-card-text>
                                                            <h4>{{ item.DedDescription + item2.DedDescription }}</h4>
                                                        </v-card-text>
                                                    </v-card>
                                                    <v-card v-else hover 
                                                        
                                                        :class="{ selectedDoll: tableClickSelectedID == item.DedAbbreviation + item2.DedAbbreviation, Doll: tableClickSelectedID != item.DedAbbreviation + item2.DedAbbreviation }"
                                                        @click="emitLocation({item , item2})">
                                                        <v-card-text>
                                                            <h4>{{ item.DedDescription + item2.DedDescription }}</h4>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-col>
                                            </v-row>                                            
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
 import _armedDoolService from '@/services/FrozenProduction/ArmedDollService'

export default {
    data(){
        return{
            dollStore: [],
            columns: [],
			rows: [],
            tableClickSelectedID: 0
        }
    },
    methods:
    {
        emitLocation(item){
            
            this.tableClickSelectedID = item.item.DedAbbreviation + item.item2.DedAbbreviation;            
            this.$emit("emitLocation", item)				
        },
        toggleSelection(item2) {
      // Toggle the selected state of the card
            item2.selected = !item2.selected;
        },
        listStore()
        {				
            _armedDoolService.listStore(this.$fun.getUserID())
            .then((r) => {
                if (r.status == 200) {
                    this.dollStore = r.data;                                   
                }
            })
        },
        clickFilterDoll(){
            _armedDoolService.listDollFilter({}, this.$fun.getUserID()).then(r => {
                if(r.status == 200){
                    // this.itemsDollFilter= r.data
                    this.dollStore = r.data;                    
                }
            })
        },
        GetColumns(items) {
				this.columns = items;				
			},

			GetRows(items)
			{
				this.rows = items;				
			},
    },
    created(){
        this.clickFilterDoll();
        this.listStore();
    }
}
</script>

<style scoped>
    .selected {
        border: 2px solid #42a5f5; /* Color del borde del card seleccionado */
    }
    .Doll{
        background-color: #5edf87
    }
    .selectedDoll{
        background-color: #2a8a66; /* Fondo de la silla seleccionada */
	    border-color: #ffffff; 
    }
</style>